<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <!-- <base-section-heading title="Who's using Zero?" /> -->
      <base-section-heading title="Who's using Hi5Magic?" />

      <v-slide-group>
        <!-- <template v-for="n in 2"> -->
        <template v-for="({ logo, title, text}, n) in affiliates">
          <v-slide-item
            :key="n"
            class="align-self-center"
          >
            <!-- <v-btn
              class="font-weight-bold"
              color="white"
              :href="text"
              light
              min-width="168"
              target="_blank"
              :text="title"
            > -->
            <a :href="text">
              <base-img
                :src="require(`@/assets/${logo}.png`)"
                color="grey"
                contain
                height="58"
                width="128"
                :title="title"
              />
            <!-- </v-btn> -->
            </a>
          </v-slide-item>

          <v-responsive
            v-if="n < affiliates.length-1"
            :key="`divider-${n}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
    data: () => ({
      affiliates: [
        {
          logo: 'sss-kids',
          title: 'StreetSmartSuperKids',
          text: 'https://www.facebook.com/StreetSmart-SuperKids-102192577888331/',
        },
        {
          logo: 'pivot',
          title: 'PIVOT',
          text: 'https://www.facebook.com/crewpivot',
        },
        {
          logo: 'nanbantraders',
          title: 'NANBAN TRADERS',
          text: 'https://www.facebook.com/nanbantradersofficial',
        },
        {
          logo: 'avc-rungs',
          title: 'AVC RUNGS',
          text: 'https://www.instagram.com/p/CMGaMnunvh6/',
        },
        {
          logo: 'tajbakery',
          title: 'TAJ BAKERY',
          text: 'https://www.instagram.com/p/CMGa1Frn-Bv/',
        },
        {
          logo: 'mayicheckin',
          title: 'MayICheckIn',
          text: 'https://www.facebook.com/mayicheckinOfficial',
        },
        {
          logo: 'svnaturals',
          title: 'SV NATURALS OIL COMPANY',
          text: '#',
        },
      ],
    }),
  }
</script>
